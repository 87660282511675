import {css, unsafeCSS} from "lit";
import { sbkCoreBreakpointLg } from '@/tokens/variables.js'

const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);

export const style = css`
  :host {
    --sitemap-column-header__color: var(--_sitemap-column-header__color, var(--sbkLinkFgColorOnDarkEnabled));
    --sitemap-column-header__font: var(--_sitemap-column-header__font, var(--sbkTypographyContentLargeScrTitleXs));
    --footer-link-container__gap--sm: var(--_footer-link-container__gap--sm, var(--sbkFooterSmallScrLinkContainerGap));
    --footer-link-container__gap--lg: var(--_footer-link-container__gap--lg, var(--sbkCoreSizingXl));
    --footer-link-container-inside-column__gap--lg: var(--_footer-link-container-inside-column__gap--lg, var(--sbkFooterLargeScrLinkContainerInsideColumnGap));
    flex-basis: 100%;
    gap: var(--footer-link-container__gap--sm);
  }

  #sitemap-panel {
    ul {
      display: flex;
      flex-direction: column;
      gap: var(--footer-link-container-inside-column__gap--lg);
    }
  }

  @media all and (min-width: ${breakpointLg}) {
    :host {
      flex: 1 0 calc(20% - var(--footer-link-container__gap--lg) - var(--sbkCoreGridLgMargin));
    }

    #sitemap-panel {
      display: block;
    }
  }

  .sitemap-column-header {
    display: inline-block;
    color: var(--sitemap-column-header__color);
    font: var(--sitemap-column-header__font);

    border: none;
    margin: 0;
    padding: 0;
    background: transparent;
    text-align: inherit;
  }

  .sitemap-column-icon {
    display: none;
  }
  
  dl {
    display: flex;
    flex-direction: column;
    gap: var(--footer-link-container__gap--sm);
  }
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  @media all and (max-width: ${breakpointLg}) {
    .sitemap-column-icon {
        display: inline-block;
        position: relative;
        top: 6px;
    }

    dt {
      margin-bottom: var(--footer-link-container-inside-column__gap--lg);
    }
  }

  @media all and (min-width: ${breakpointLg}) {
    dl {
      gap: var(--footer-link-container-inside-column__gap--lg);
    }
  }
  
  dl, dt, dd {
    margin: 0;
  }

  .sitemap-column-header-desktop {
    display: none;
  }

  @media all and (min-width:  ${breakpointLg}) {

    .sitemap-column-header-mobile {
      display: none;
    }

    .sitemap-column-header-desktop {
      display: block;
    }
  }
`;
